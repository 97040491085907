<template>
  <div class="search_bar">
    <!-- <div style="height: 40px; width: 40px; background-color: black; position: relative; z-index: 1;" @click="here"></div> -->
    <input placeholder="Search" ref="searchWindow" class="rounded-box search-text-input" type="text" v-model="inputValue" @keyup="onSubmit">
    <span v-if="displayWidth < 901" class="search_close" @click="store.state.search = !store.state.search"></span>
    
    <div class="result" v-if="inputValue !== ''" :style="route.name === 'Home' || route.name === 'Article' ? {'bottom': '0px'} : null">
      <div ref="searchTabs">
        <div :class="'search-entity' + (postsCheck ? ' search-entity-active' : '')" @click.prevent="postsCheck = !postsCheck">Pages <span>P</span></div>
        <div :class="'search-entity' + (booksCheck ? ' search-entity-active' : '')" @click.prevent="booksCheck = !booksCheck">Books <span>B</span></div>
        <div :class="'search-entity' + (sectionsCheck ? ' search-entity-active' : '')" @click.prevent="sectionsCheck = !sectionsCheck">Sections <span>S</span></div>
        <div :class="'search-entity' + (libCleck ? ' search-entity-active' : '')" @click.prevent="libCleck = !libCleck">Libraries <span>L</span></div>
        <div :class="'search-entity' + (categoriesCheck ? ' search-entity-active' : '')" @click.prevent="categoriesCheck = !categoriesCheck">Categories</div>
        <div :class="'search-entity' + (htmlTagsCheck ? ' search-entity-active' : '')" @click.prevent="htmlTagsCheck = !htmlTagsCheck">HTML Tags</div>
      </div>

      <div v-if="postsCheck && results.posts?.length > 0" v-for="result in results.posts">
        <a class="link" @click.prevent="letsGo(result)">
          <div class="books_result">
            <div class="breadcrumb"><span>P</span><span>{{result.search_breadcrumbs}}</span></div>
            <div class="search-result-header">
              <span class="title" v-html="highlightSearchedText(result.content.title)"></span>
              <!-- <span class="type">(Page)</span> -->
            </div>
              <span style="color:#777;" v-html="highlightSearchedTextInPost(inputValue, result.content.html)"></span>
              <p v-if="highlightSearchedTextInPostCount(inputValue, result.content.html) > 1" class="hint">[ There are multiple instances of the search term in this Post ]</p>
          </div>
        </a>
      </div>
      <div v-if="booksCheck && results.articles?.length > 0" v-for="result in results.articles">
        <a class="link" :href="result.linkUrl">
          <div class="books_result">
            <div class="breadcrumb"><span>B</span><span>{{result.search_breadcrumbs}}</span></div>
            <div class="search-result-header">
              <span class="title" v-html="highlightSearchedText(result.name)"></span>
              <!-- <span class="type">(Book)</span> -->
            </div>
            <p class="desc" v-html="highlightSearchedText(result.subTitle)"></p>
          </div>
        </a>
      </div>
      <div v-if="sectionsCheck && results.sections?.length > 0" v-for="result in results.sections">
        <a class="link" :href="result.linkUrl">
          <div class="sections_result">
            <div class="breadcrumb"><span>S</span><span>{{result.search_breadcrumbs}}</span></div>
            <div class="search-result-header">
              <span class="title" v-html="highlightSearchedText(result.Name)"></span>
              <!-- <span class="type">Section</span> -->
            </div>
          </div>
        </a>
      </div>
      <div v-if="libCleck && results.kb?.length > 0" v-for="result in results.kb">
        <a class="link" :href="result.linkUrl">
          <div class="locations_result">
            <div class="breadcrumb"><span>L</span><span>{{result.search_breadcrumbs}}</span></div>
            <div class="search-result-header">
              <span class="title" v-html="highlightSearchedText(result.Name)"></span>
              <!-- <span class="type">(Library)</span> -->
            </div>
          </div>
        </a>
      </div>
      <div v-if="categoriesCheck && results.categories?.length > 0" v-for="result in results.categories">
        <a class="link" :href="result.linkUrl">
          <div class="books_result">
            <div class="search-result-header">
              <div class="breadcrumb"><span>B</span><span>{{result.search_breadcrumbs}}</span></div>
              <span class="title" v-html="highlightSearchedText(result.name)"></span>
              <!-- <span class="type">(Book)</span> -->
            </div>
            <p class="desc" v-html="highlightSearchedText(result.subTitle)"></p>
            <p class="desc" v-html="'Category: ' + highlightSearchedText(result.categoryName)"></p>
          </div>
        </a>
      </div>
      <div v-if="htmlTagsCheck && results.posts?.length > 0" v-for="result in results.posts">
        <a class="link" :href="result.linkUrl">
          <div class="books_result">
            <div class="search-result-header">
              <div class="breadcrumb"><span>B</span><span>{{result.search_breadcrumbs}}</span></div>
              <span class="title" v-html="highlightSearchedText(result.content.title)"></span>
              <!-- <span class="type">(Page)</span> -->
            </div>
            <p class="desc">{{ highlightSearchedTextInPost(inputValue, result.content.html, true) }}</p>
            <p class="hint">[ Search term not highlighted due to HTML Tags option ]</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, inject, nextTick} from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import mitt from "mitt";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const emitter = mitt();
  const apiPath = inject('apiPath');
  let inputValue = ref("");
  let results = ref({});
  const emit = defineEmits(['close']);
  const close = () => emit('here', val);
  const searchWindow = ref(null);
  const searchTabs = ref(null);
  const booksCheck = ref(true);
  const postsCheck = ref(true);
  const categoriesCheck = ref(false);
  const sectionsCheck = ref(true);
  const libCleck = ref(true);
  const htmlTagsCheck = ref(false);
  const locationsCheck = ref(false);
  const displayWidth = ref(window.innerWidth);
  const focusInput = () => {
    if (searchWindow.value) {
      searchWindow.value.focus();
    }
  };
  onMounted(async function () {
    focusInput;
    await nextTick();
    document.addEventListener('click', function (event) {

      if (searchWindow.value !== null && searchTabs.value !== null) {
        const isClickOutside = !searchWindow?.value.contains(event.target) && !searchTabs?.value.contains(event.target);

        if (isClickOutside) {
          inputValue.value = "";
        }
      } else {
        inputValue.value = "";
      }
    });
  });
  function letsGo(result) {
    store.state.postToScroll = result.postid;
    if (route.name === 'Article' && (result.parentid === route.params.id || result.parentid === '0' && route.params.id === 'unpublished')) {
      emitter.emit("scroll",'this');
    } else {
      const link = '/article/' + (result.parentid !== '0' ? result.parentid : 'unpublished');
      router.push({ path: link });
    }
    
  }
  function here(){
    console.log(booksCheck.value)
  }

  function contentConditioning(c) {
    if (c === undefined) {
      return c;
    }
    try {
      let r =  "<span style='font-size: 1rem; line-height: 1.2rem; color:black;text-decoration: underline;'>";
      r += "</span><span style='font-size: 1rem; line-height: 1.2rem; color:#777;text-decoration: none;'> ...";
      r += decodeURIComponent((c).replace(/\+/g, '%20')) + "...</span>";
      return r;
    } catch (err) {
      console.log(err);
    }
    return c;
  }

  function highlightSearchedText(content) {
    if (content === undefined) {
      return content;
    }
    const regex = new RegExp(`(${inputValue.value})`, 'gi');

    return content.replaceAll(regex, '<strong style=\'color:#006c3e;\'>$1</strong>');
  }

  function highlightSearchedTextInPost(substring, inputString, justTrim = false) {
      if (substring === undefined || inputString === undefined) {
          return inputString;
      }
      
      // Convert HTML to plain text and normalize whitespace
      inputString = inputString
          .replace(/<[^>]+>/g, ' ')      // remove all HTML tags
          .replace(/&nbsp;/g, ' ')       // replace &nbsp; with space
          .replace(/&[^;]+;/g, '')       // remove other HTML entities
          .replace(/\r?\n|\r/g, ' ')     // handles \n, \r\n, and \r
          .replace(/\s+/g, ' ')          // replaces multiple spaces with single space
          .trim();                       // removes leading/trailing whitespace
      
      const wordsLimit = 10;
      const words = inputString.split(/\s+/);
      
      // If search term is found, center around it
      const lowerInputString = inputString.toLowerCase();
      const lowerSubstring = substring.toLowerCase();
      const index = lowerInputString.indexOf(lowerSubstring);
      
      let start, end;
      if (index !== -1) {
          // Find the word containing the match
          let startWordIndex = 0;
          let charCount = 0;
          for (let i = 0; i < words.length; i++) {
              charCount += words[i].length + 1;
              if (charCount > index) {
                  startWordIndex = i;
                  break;
              }
          }
          
          start = Math.max(0, startWordIndex - wordsLimit);
          end = Math.min(words.length, startWordIndex + wordsLimit);
      } else {
          // If no match, just take first few words
          start = 0;
          end = Math.min(words.length, wordsLimit * 2);
      }

      const trimmedWords = words.slice(start, end);
      let trimmedString = trimmedWords.join(' ');

      if (!justTrim && index !== -1) {
          const regex = new RegExp(`(${substring})`, 'gi');
          trimmedString = trimmedString.replace(regex, '<strong style="color:#006c3e;">$1</strong>');
      }

      return ((start > 0) ? '... ' : '') + trimmedString + ((end < words.length) ? ' ...' : '');
  }

  function highlightSearchedTextInPostCount(substring, inputString) {
    if (substring === undefined || inputString === undefined) {
      return 0;
    }

    const lowerInputString = inputString.toLowerCase();
    const lowerSubstring = substring.toLowerCase();

    return lowerInputString.split(lowerSubstring).length - 1;
  }

  function onSubmit() {
    const url = `${apiPath}searchNew?token=${store.state.usertoken}&json&s=${inputValue.value}`;
    //const url = "https://devteamapi.wave7.cloud/search?json&s="+inputValue.value;
    if(inputValue.value !== "") {
      fetch(url, {
        headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
        let info = response;
        Object.entries(info).forEach((data) => {
          let type = data[0];
          if (data[1].length > 0) {
            data[1].forEach((element, key) => {
              switch (type) {
                case "kb":
                  info[type][key].linkUrl = window.location.origin + '/machine/' + element.slocationid;
                  break;
                case "sections":
                  info[type][key].linkUrl = window.location.origin + '/section/' + element.sectionid;
                  break;
                case "articles":
                  info[type][key].linkUrl = window.location.origin + '/article/' + element.articleid;
                  break;
                case "posts":
                  info[type][key].linkUrl = window.location.origin + '/article/' + element.parentid;
                  info[type][key].content = JSON.parse(element.content);
                  break;
                case "categories":
                  info[type][key].linkUrl = window.location.origin + '/article/' + element.articleid;
                  break;
              }
              info[type][key].search_breadcrumbs = (element.search_breadcrumbs !== null && element.search_breadcrumbs !== '' ? trimLongStrings(element.search_breadcrumbs.split('/')).join("/") : '');
            });
          }
        });
        results.value = info;
        console.log("Search Result", response);
      }).catch((error) => {
        console.log("error in search fetch", error);
      });
    } else {
      results.value = {};
    }
  }
  function trimLongStrings(strings) {
    let result = strings.slice(0, -1).map(str => {
      if (str.length > 7) {
        return str.substring(0, 6) + "..";
      }
      return str;
    });
    result.push(strings[strings.length - 1]);

    return result;
  }
</script>

<style lang="scss" scoped>
  .search_close,.search_bar input{
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .search_bar{
    display: flex !important;
    background-color: #fff;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    .search-entity {
      display: inline-block;
      background-color: #bcbcbc;
      height: 34px;
      line-height: 34px;
      border-radius: 8px;
      margin: 0 5px;
      padding: 0 5px;
      font-size: 1rem;
      font-weight: 400;
      color: #ffffff;
      span{
        padding: 0 2px;
        line-height: 21px;
        border: 1px solid #fff;
        border-radius: 2px;
        display: inline-block;
      }
      @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
        height: 31px;
        line-height: 31px;
      }
    }
    .search-entity-active {
      background-color: #099f45;
    }
    .search-text-input{
      padding: 0;
      border: 0;
      background-color: rgba(120, 120, 120, 0.05);
      // width: calc(100% - 48px);
      flex-grow: 1;
      box-sizing: border-box;
      border-radius: 8px;
      background-image: url(../../../public/img/search.svg);
      background-position: 12px center;
      background-size: 30px;
      font-size: 1rem;
      &:active{
        border: 0;
        outline: none;
        //text-indent: 8px;
        text-indent: 45px;
      }
      &:focus{
        border: 0;
        outline: none;
        //text-indent: 8px;
        text-indent: 45px;
      }
    }
    .search-text-input:focus::placeholder,input:active::placeholder{
      color: transparent;
    }
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   padding: 0 15px;
    // }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      // padding: 0 50px;
      width: 410px;
      padding-right: 12px;
      .search-text-input{
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-indent: 50px;
      }
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      // padding: 0 40px;
      width: 400px;
      padding-right: 12px;
      .search-text-input{
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-indent: 50px;
      }
    }
    // TABLET + MOBILE
    @media screen and (min-width: 481px) and (max-width: 900px), screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      bottom: -40px;
      .search_close{
        display: inline-block;
      }
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
      padding: 0 30px;
      height: 45px;
      .search-text-input{
        height: 40px;
        line-height: 40px;
        text-indent: 50px;
      }
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      padding: 0 15px;
      height: 40px;
      .search-text-input{
        height: 36px;
        line-height: 36px;
        text-indent: 48px;
      }
    }
    span{
      //width: 32px;
      border: 0;
      // background-color: #fff;
      outline: none;
    }
    .search_close{
      background-image: url(../../../public/img/close.svg);
      margin-left: 6px;
      width: 40px;
      height: 40px;
    }
    .result::before{
      content: '';
      display: block;
      height: 0px;
      position: sticky;
      top: 0;
      margin-left: -200px;
      margin-right: -200px;
      box-shadow: 0px 2px 8px 2px rgba(0,0,0,.099);
    }
    .result{
      position: fixed;
      z-index: 1;
      left: 0;
      right: 0;
      background-color: #F2F2F2;
      overflow-y: auto;
      a{
        text-decoration: none;
        color: #444;
      }
      // @media screen and (min-width: 27 01px) and (max-width: 3840px){
      //   padding: 0 50px;
      //   top: 150px;
      //   bottom: 169px;
      // }
      // DESKTOP
      // @media screen and (min-width: 1025px) and (max-width: 27 00px){
      @media screen and (min-width: 1025px){
        padding: 0 50px;
        top: 64px;
        bottom: 72px;
      }
      // LAPTOP
      @media screen and (min-width: 901px) and (max-width: 1024px){
        padding: 0 40px;
        top: 64px;
        bottom: 72px;
      }
      // TABLET
      @media screen and (min-width: 481px) and (max-width: 900px){
        padding: 0 30px;
        top: 92px;
        bottom: 72px;
      }
      @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
        padding: 0 15px;
        top: 84px;
        bottom: 64px;
      }
      input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
        margin: 0;
      }
      label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        color: #787878;
        font-size: 1rem;
        height: 40px;
        padding-right: 30px;
        position: relative;
        margin-right: 15px;
      }
      strong{
        line-height: inherit;
      }
      .checkbox {
        content: '';
        display: inline-block;
        width: 21px;
        height: 21px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #787878;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 0;
      }
      input[type="checkbox"]:checked+.checkbox {
        background-image: url(../../../public/img/check.svg);
        background-size: 10px;
        background-color: #787878;
      }
    }
    .books_result,.sections_result,.locations_result{
      box-sizing: border-box;
      width: 100%;
      background-color: #fff;
      margin-bottom: 10px;
      border-radius: 10px;
      font-size: 1rem;
      overflow: hidden;
      word-wrap: break-word;
      border: 1px solid #bcbcbc;
      padding: 7px 20px;
      @media screen and (min-width: 481px) and (max-width: 900px){
        border-radius: 16px;
      }
      @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
        border-radius: 10px;
        padding: 7px 15px;
      }
      .breadcrumb{
        margin-top: -7px;
        margin-left: -20px;
        margin-right: -20px;
        font-size: 18px;
        height: 39px;
        line-height: 39px;
        padding: 0 16px;
        background-color: #eee;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
          margin-left: -15px;
          margin-right: -15px;
        }
        span{
          padding: 0 2px;
          line-height: 21px;
          display: inline;
          margin-right: 7px;
          font-size: 1rem;
        }
        span:first-child{
          border: 1px solid #787878;
          display: inline-block;
          border-radius: 2px;
        }
      }
      .search-result-header {
        display: flex;
        justify-content: space-between;
        line-height: 1.5rem;
        height: 28px;
      }
      a{
        text-decoration: none;
        color: #444;
      }
      .title{
        margin-top: auto;
        margin-bottom: auto;
        color: #252525;
        font-weight: 500;
      }
      .type{
        border-radius: 6px;
        padding: 0 2px 2px 2px;
        color: #787878;
        background-color: #eaeaea;
      }
      .hint{
        margin: 0 0 10px 0;
        padding: 5px 7px 3px;
        color: #787878;
        background-color: #fff;
        margin-bottom: -7px;
        margin-left: -20px;
        margin-right: -20px;
        @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
          margin-left: -15px;
          margin-right: -15px;
        }
      }
      .desc{
        margin: 0 0 5px 0;
      }
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px)
  and (min-height: 961px) and (orientation: portrait){
    .search_bar{
    // position: initial;
    width: 100%;
    input{
      width: 100%;
    }
  }
}
@media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
  .search_bar{
    // position: initial;
    input{
      width: 100%;
      text-align: left;
    }
  }
}
</style>
